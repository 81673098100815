import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=180f44ed&scoped=true&"
var script = {}
import style0 from "./NavBar.vue?vue&type=style&index=0&id=180f44ed&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180f44ed",
  null
  
)

export default component.exports