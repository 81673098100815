var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('h2',{staticClass:"no-padding"},[_vm._v("Book Another Slot")]),(!_vm.foodbanks.length)?_c('section',[_vm._v("None found")]):_vm._e(),_c('div',{staticClass:"options"},[_c('GmapMap',{staticStyle:{"flex":"1","height":"700px","margin-right":"70px","border-radius":"15px","overflow":"hidden"},attrs:{"center":_vm.center,"zoom":14,"options":{
				zoomControl: false,
				scaleControl: false,
				mapTypeControl: false,
				fullscreenControl: false,
				streetViewControl: false,
				disableDefaultUi: false,
			}}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true},on:{"click":function($event){return _vm.toggleInfo(m, index)}}})}),1),_c('section',{staticStyle:{"flex":"1"}},[_c('h3',{staticClass:"no-padding"},[_vm._v("Food Banks")]),_vm._l((_vm.sortBanks([..._vm.foodbanks])),function(foodbank,index){return _c('BookSlot',{key:foodbank.name,attrs:{"foodbank":foodbank,"distance":_vm.calculateDistance(foodbank.lat, foodbank.lng),"selected":_vm.selectedFoodbank !== null && 0 === index}})})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }