import { render, staticRenderFns } from "./PastOrder.vue?vue&type=template&id=81bc4d98&scoped=true&"
import script from "./PastOrder.vue?vue&type=script&lang=js&"
export * from "./PastOrder.vue?vue&type=script&lang=js&"
import style0 from "./PastOrder.vue?vue&type=style&index=0&id=81bc4d98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81bc4d98",
  null
  
)

export default component.exports